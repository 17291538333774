@use "../../styles/colors.scss" as color;
@use "../../styles/utils.scss" as utils;
.main-header {
  position: fixed;
  width: 100%;
  height: 3rem;
  z-index: 1000;
  background-color: color.$lightturq-dark;
  @include utils.centeredSpaceBetween;
  @include utils.horizontalPadding;
  p {
    margin-left: 5px;
    font-size: 1.2em;
    text-transform: uppercase;
    letter-spacing: 0.3em;
  }
  button {
    @include utils.centered;
    .logo {
      background-color: color.$white;
      padding: 0.3rem;
    }
    .menu-icon {
      color: color.$white;
      height: 2rem;
      width: 2rem;
    }
  }
}
.colored {
  background-color: color.$turq;
}
.menu {
  z-index: 10000;
  position: absolute;
  @include utils.centered;
  width: 100%;
  height: 100%;
  background-color: color.$purple-dark;
  ul {
    list-style: none;
    width: 70%;
    margin-bottom: auto;
    li {
      text-align: center;
      text-transform: uppercase;
      color: color.$white;
      border-bottom: 1px solid rgba($color: color.$white, $alpha: 0.2);
      padding: 0.8rem 0;
      letter-spacing: 0.3em;
      &.active {
        font-weight: bold;
      }
    }
    li:last-child {
      border: none;
    }
  }
  .close-btn {
    color: color.$white;
    align-self: flex-end;
    margin-bottom: auto;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    width: 2rem;
    height: 2rem;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .open {
    animation: Open-menu 0.5s linear;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .close {
    animation: Close-menu 0.5s linear;
  }
}

@keyframes Open-menu {
  from {
    margin-left: 100%;
    width: 300%;
  }
  to {
    margin-left: 0%;
    width: 100%;
  }
}
@keyframes Close-menu {
  from {
    margin-left: 0%;
  }
  to {
    margin-left: 100%;
  }
}
// .main-nav {
//   padding: 0.3rem 1rem;
//   background-color: color.$white;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   bottom: 0;
//   right: 0;
//   left: 0;
//   position: fixed;
//   border-top: 1px solid color.$white-dark;
//   button {
//     .link {
//       color: color.$black;
//     }
//     .active {
//       color: color.$orange;
//     }
//     .disabled {
//       color: color.$grey;
//     }
//   }
// }
