@use "./styles/colors.scss" as color;
@use "./styles/utils.scss" as utils;
.App {
  background-color: color.$lightturq-dark;
  height: 100vh;
  margin: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left) !important;
  .main-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .main-footer {
    padding: 1rem 0;
    @include utils.horizontalPadding;
    @include utils.rowCentered;
    background-color: black;
    > * {
      height: 24px;
      color: color.$white;
      margin-right: 1rem;
    }
  }
}
@media (prefers-reduced-motion: no-preference) {
  .social-hint {
    animation: Social-hint 4s ease infinite;
  }
}

@keyframes Social-hint {
  0% {
    transform: translateX(3rem);
  }
  50% {
    transform: translateX(-0.5rem);
  }
  100% {
    transform: translateX(3rem);
  }
}
