@use "../../styles/colors.scss" as color;
@use "../../styles/utils.scss" as utils;
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
  background-color: rgba($color: #000000, $alpha: 0.8);

  @include utils.centered;
  .install-button {
    @include utils.buttonOutlined;
    margin-bottom: 1rem;
    width: 6rem;
  }
  h1 {
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 1rem;
  }
}
.games {
  @include utils.menuPadding;
  .games-header {
    @include utils.rowCentered;
    justify-content: center;
    background-color: color.$cleargreen-dark;
    h1 {
      margin: auto;
      text-transform: uppercase;
      font-weight: normal;
    }
    .chevron {
      color: color.$white;
      &:nth-child(3) {
        opacity: 0;
      }
    }
  }
  .grid-container {
    // --auto-grid-min-size: 16rem;
    // display: grid;
    // grid-template-columns: repeat(
    //   auto-fill,
    //   minmax(var(--auto-grid-min-size), 1fr)
    // );
    display: grid;
    grid-template-columns: 50% 50%;
    div {
      padding: 1.5rem 0 0;
      @include utils.horizontalPadding;
      min-height: 150px;
      display: flex;
      justify-content: space-between;
      img {
        margin-top: 1rem;
      }
      p {
        font-weight: bold;
      }
      &:first-child {
        background-color: color.$turq-dark;
      }
      &:nth-child(2) {
        background-color: color.$purple-dark;
      }
      &:nth-child(3) {
        background-color: color.$lightturq;
      }
      &:nth-child(4) {
        background-color: color.$darkgrey;
      }
      &:nth-child(5) {
        background-color: color.$purple;
      }
      &:nth-child(6) {
        background-color: color.$green;
      }
      &:nth-child(7) {
        background-color: color.$orange;
      }
      &:nth-child(8) {
        background-color: color.$blue;
      }
    }
  }
}
