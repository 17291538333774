$red: #de3f33;
$red-dark: #a72f26;
$orange: #f59600;
$orange-dark: #b87100;
$cleargreen: #5abf23;
$cleargreen-dark: #408434;
$green: #99b25f;
$green-dark: #738748;
$lightturq: #9bc8cb;
$lightturq-dark: #749698;
$turq: #2c6a75;
$turq-dark: #215058;
$blue: #6aa8d2;
$blue-dark: #3e64a4;
$purple: #77477e;
$purple-dark: #59355f;
$black: #0f0f0f;
$black-dark: #000000;
$darkgrey: #292929;
$darkgrey-dark: #1d1d1d;
$grey: #a2a2a2;
$grey-dark: #7a7a7a;
$white: #fefefe;
$white-dark: #f4f4f4;
