@use "../../../styles/colors.scss" as color;
@use "../../../styles/utils.scss" as utils;

.memory {
  @include utils.centered;
  background-color: color.$turq-dark;
  min-height: 40rem;
  justify-content: flex-start;
  h1 {
    margin-top: 1rem;
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    max-width: 23rem;
  }
  .reset {
    height: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .level-container {
    padding: 1rem 0rem;
  }
  .level-container button {
    @include utils.buttonOutlined;
    margin: 0 0.5rem;
  }
  .level-container button.act {
    background-color: color.$white;
    color: color.$black;
    font-weight: bold;
  }
  .score-container {
    text-align: center;
    margin-top: auto;
    margin-bottom: 1rem;
  }
  .card {
    width: 5rem;
    height: 5rem;
    user-select: none;
    cursor: pointer;
    display: grid;
    place-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom left, #f59600 50%, #b87100 50%);
    transition: transform 0.5s ease;
    backface-visibility: hidden;
    border-radius: 1rem;
    overflow: hidden;
  }
  .card-container {
    width: 5rem;
    height: 5rem;
    position: relative;
  }
  .card-flip {
    transform: rotateY(180deg);
  }
  img {
    max-width: 100%;
  }
}
@media only screen and (min-width: 800px) {
  .card {
    width: 5rem;
    height: 5rem;
    user-select: none;
    cursor: pointer;
    display: grid;
    place-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom left, #f59600 50%, #b87100 50%);
    transition: transform 0.5s ease;
    backface-visibility: hidden;
    border-radius: 1rem;
    overflow: hidden;
  }
}
