@use "./colors.scss" as color;
@mixin centeredSpaceBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@mixin centered() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@mixin rowCentered() {
  display: flex;
  align-items: center;
}
@mixin horizontalPadding() {
  padding-right: 1rem;
  padding-left: 1rem;
}
@mixin menuPadding() {
  padding-top: 3rem;
}
@mixin buttonOutlined() {
  color: color.$white;
  border: 1px solid color.$white;
  &:hover {
    border: 1px solid color.$white;
    font-weight: bold;
  }
  &:active {
    border: 1px solid color.$white;
    transform: translateY(2px);
  }
}
