@use "../../styles/colors.scss" as color;
@use "../../styles/utils.scss" as utils;

.home {
  @include utils.menuPadding;
  color: color.$white;
  font-weight: normal;
  display: grid;
  grid-auto-columns: 1fr;
  .banner {
    grid-column-end: span 2;
  }
  header,
  div {
    padding: 1.5rem 0;
    @include utils.horizontalPadding;
    min-height: 150px;
  }
  div {
    @include utils.centeredSpaceBetween;
    &:nth-child(2) {
      background-color: color.$cleargreen-dark;
      flex-direction: column;
      align-items: stretch;
    }
    &:nth-child(3) {
      background-color: color.$purple;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      aside {
        min-width: 100%;
        @include utils.centeredSpaceBetween;
        scroll-snap-align: center;
      }
      article {
        min-width: 100%;
        margin: 0 1rem;
        padding: 0 1rem;
        scroll-snap-align: center;
        @include utils.centeredSpaceBetween;
      }
    }
    &:nth-child(4) {
      background-color: color.$turq-dark;
    }
    &:nth-child(5) {
      background-color: color.$grey-dark;
      padding: 3rem;
    }
    &:nth-child(6) {
      background-color: color.$purple-dark;
      img {
        width: 150px;
      }
      .small-text {
        font-size: 10px;
      }
    }
  }
  button {
    color: color.$white;
    border: 1px solid color.$white;
    margin: 1.5rem 0 0;
    &:hover {
      border: 1px solid color.$white;
      font-weight: bold;
    }
    &:active {
      border: 1px solid color.$white;
      transform: translateY(2px);
    }
  }
  hr {
    margin: 1rem 0;
  }
  h1,
  h2,
  h3 {
    font-weight: normal;
  }
}
@media only screen and (max-width: 480px) {
  .home {
    //grid-auto-columns: 2fr;
    div {
      grid-column-end: span 2;
    }
  }
}
@media (prefers-reduced-motion: no-preference) {
  .arrow {
    animation: Scroll-hint 4s ease infinite;
  }
}

@keyframes Scroll-hint {
  0% {
    transform: translateX(-1rem);
  }
  50% {
    transform: translateX(0rem);
  }
  100% {
    transform: translateX(-1rem);
  }
}

// @keyframes Scroll-hint {
//   from {
//     transform: translateX(-1rem);
//   }
//   to {
//     transform: translateX();
//   }
//   to {
//     transform: translateX(-1rem);
//   }
// }
