@use "../../../styles/colors.scss" as color;
@use "../../../styles/utils.scss" as utils;
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
.tictactoe {
  background-color: color.$darkgrey;
  min-height: 37rem;
  @include utils.centered;
  p {
    margin-bottom: 1rem;
  }
  .board {
    .square {
      background: color.$darkgrey;
      border: 3px solid color.$white;
      float: left;
      color: color.$white;
      font-size: 5rem;
      font-weight: normal;
      line-height: 34px;
      height: 7rem;
      width: 7rem;
      margin-right: -1px;
      margin-top: -1px;
      padding: 0;
      text-align: center;
      font-family: "Raleway";
    }
    .board-row:after {
      clear: both;
      content: "";
      display: table;
    }
  }
  .status {
    margin: 2rem;
    font-size: 2em;
    .win {
      color: color.$cleargreen-dark;
    }
  }
  button {
    @include utils.buttonOutlined;
    width: 10rem;
  }
}
