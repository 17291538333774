@use "../../../styles/colors.scss" as color;
@use "../../../styles/utils.scss" as utils;

.hangman {
  background-color: color.$purple-dark;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  @include utils.horizontalPadding;
  .level {
    text-align: center;
    button {
      margin: 1rem 0.5rem;
    }
  }
  .restart-button {
    color: color.$white;
    border: 1px solid color.$white;
    margin: 1.5rem 0 2rem 0;
    &:hover {
      border: 1px solid color.$white;
      font-weight: bold;
    }
    &:active {
      border: 1px solid color.$white;
      transform: translateY(2px);
    }
  }
  .center {
    text-align: center;
    margin-top: auto;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;
    width: 100%;
    .info {
      @include utils.centered;
    }
    .small-text {
      font-size: 8px;
      margin-top: 5px;
    }
    .circle {
      background-color: color.$cleargreen-dark;
      height: 2.5rem;
      width: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3rem;
      font-weight: bold;
      color: color.$white;
      border: 1px solid color.$white;
    }
    .danger {
      background-color: color.$red-dark;
    }
  }
  .container {
    @include utils.centered;
    height: 8.5rem;
  }
  .result {
    text-align: center;
    margin-bottom: 1rem;
  }
  h1 {
    font-size: 1.2em;
    font-weight: normal;
  }
  .display-word {
    font-size: 1.5em;
    text-align: center;
  }
  .all-letters {
    max-width: 25rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    button {
      width: 1.9rem;
      height: 1.9rem;
      font-size: 1.3em;
      border-radius: 0.2rem;
      margin: 0.2rem;
      background-color: color.$white;
      color: color.$black-dark;
      &.disabled {
        background-color: color.$black-dark;
      }
    }
  }
  .hangman-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 12rem;
    margin-top: 1rem;
    .bottom {
      border-bottom: 4px solid color.$black-dark;
      width: 12rem;
    }
    .angle {
      border-bottom: 3px solid color.$black-dark;
      width: 3.5rem;
      rotate: -45deg;
      position: relative;
      top: 1rem;
      left: -0.6rem;
    }
    .middle {
      width: 6rem;
      display: flex;
      flex-direction: row;
    }
    .left {
      border-left: 4px solid color.$black-dark;
      height: 12rem;
    }
    .top {
      border-top: 4px solid color.$black-dark;
      width: 5rem;
    }
    .rope {
      border-left: 2px solid color.$black-dark;
      height: 3rem;
      position: relative;
      top: -0.3rem;
      left: 3.5rem;
    }
    .body {
      position: relative;
      top: 2rem;
      left: -1.5rem;
    }
    .head {
      border: 2px solid color.$black-dark;
      width: 3rem;
      height: 3rem;
      border-radius: 3rem;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      color: color.$black-dark;
    }
    .spine {
      border-left: 2px solid color.$black-dark;
      height: 3.3rem;
      position: relative;
      top: -0.4rem;
      left: 0.5rem;
    }
    .left-arm {
      border-left: 2px solid color.$black-dark;
      height: 2rem;
      position: absolute;
      top: 0.9rem;
      left: -0.4rem;
      rotate: 17deg;
    }
    .right-arm {
      border-left: 2px solid color.$black-dark;
      height: 2rem;
      position: absolute;
      top: 1rem;
      left: 0.1rem;
      rotate: -12deg;
    }
    .left-leg {
      border-left: 2px solid color.$black-dark;
      height: 2rem;
      position: absolute;
      top: 2.9rem;
      left: -0.4rem;
      rotate: 17deg;
      div {
        border-left: 2px solid color.$black-dark;
        height: 0.5rem;
        position: absolute;
        top: 1.9rem;
        left: 0rem;
        rotate: -37deg;
      }
    }
    .right-leg {
      border-left: 2px solid color.$black-dark;
      height: 2rem;
      position: absolute;
      top: 3rem;
      left: 0.1rem;
      rotate: -12deg;
      div {
        border-left: 2px solid color.$black-dark;
        height: 0.4rem;
        position: absolute;
        top: 1.95rem;
        left: -0.02rem;
        rotate: -24deg;
      }
    }
  }
}
